import { InitStorageAction as InitStorageItemAction, TypeKeys as ItemTypeKeys } from '@common/react/store/Item';

import { Init } from '@app/objects/Init';

export const customReduxActions = (dispatch, init: Init) => {
	const buildDataAction: InitStorageItemAction = {
		type: ItemTypeKeys.INITSTORAGE,
		storageName: 'buildData',
		item: {
			buildNumber: init.buildNumber,
			buildDate: init.buildDate,
			maxNumberLimit: init.maxNumberLimit,
		},
	};

	dispatch(buildDataAction);

	if (init.routesPropsPagesMap) {
		const routesPropsPagesMapAction = {
			type: ItemTypeKeys.INITSTORAGE,
			storageName: 'routesPropsPagesMap',
			item: init.routesPropsPagesMap,
		} as InitStorageItemAction;

		dispatch(routesPropsPagesMapAction);
	}
};
